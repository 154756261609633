import React from 'react';
import { Bling as GPT } from 'react-gpt';
import css from '~/components/Ad/Ad.scss';
import Styled from '~/components/Styled/Styled';

// tslint:disable-next-line variable-name
import logEvent from '~/util/analytics';

/* global DFP_AD_BASE */
declare var DFP_AD_BASE: string;

GPT.enableSingleRequest(); // Enable Google publisher tag single request architecture

export const AdTypes = {
  BIGBOX_DESKTOP: 'bigboxDesktop',
  BIGBOX_MOBILE: 'bigboxMobile',
  LEADERBOARD: 'leaderboard',
  WRAP: 'wrap',
  MOBILE_BANNER: 'mobileBanner',

  TEADS: 'teads',
};

const BIGBOX_DESKTOP_PARAMS = {
  adUnit: '300x250',
  sizeMapping: [
    { viewport: [0, 0], slot: [1, 1] },
    { viewport: [1050, 200], slot: [[300, 250], [300, 600]] },
  ],
  outOfPage: false,
};
const BIGBOX_MOBILE_PARAMS = {
  adUnit: '300x250',
  sizeMapping: [
    { viewport: [0, 0], slot: [[300, 250], [300, 600]] },
    { viewport: [1050, 200], slot: [1, 1] },
  ],
  outOfPage: false,
};
const LEADERBOARD_PARAMS = {
  adUnit: '728x90',
  sizeMapping: [
    { viewport: [0, 0], slot: [1, 1] },
    { viewport: [1050, 200], slot: [[728, 90], [970, 90], [970, 250]] },
  ],
  outOfPage: false,
};
const WRAP_PARAMS = {
  adUnit: 'Wrap',
  sizeMapping: [{ viewport: [0, 0], slot: [1, 1] }, { viewport: [1050, 200], slot: [1, 1] }],
  outOfPage: true,
};
const TEADS_PARAMS = {
  adUnit: 'Teads_Video',
  sizeMapping: [{ viewport: [0, 0], slot: [300, 250] }],
  outOfPage: false,
};

const MOBILE_BANNER_PARAMS = {
  adUnit: '320x50',
  sizeMapping: [{ viewport: [0, 0], slot: [320, 50] }],
  outOfPage: false,
};

const getSlotParams = adType => {
  switch (adType) {
    case AdTypes.BIGBOX_DESKTOP:
      return BIGBOX_DESKTOP_PARAMS;
    case AdTypes.BIGBOX_MOBILE:
      return BIGBOX_MOBILE_PARAMS;
    case AdTypes.LEADERBOARD:
      return LEADERBOARD_PARAMS;
    case AdTypes.WRAP:
      return WRAP_PARAMS;
    case AdTypes.MOBILE_BANNER:
      return MOBILE_BANNER_PARAMS;
    case AdTypes.TEADS:
      return TEADS_PARAMS;
    default:
      return BIGBOX_DESKTOP_PARAMS;
  }
};

const getContainerClass = adType => {
  switch (adType) {
    case AdTypes.TEADS:
      return css.teads;
    case AdTypes.BIGBOX_DESKTOP:
      return css.bigboxDesktop;
    case AdTypes.BIGBOX_MOBILE:
      return css.bigboxMobile;
    case AdTypes.LEADERBOARD:
      return css.leaderboard;
    case AdTypes.WRAP:
      return css.wrap;
    case AdTypes.MOBILE_BANNER:
      return css.mobileBanner;
    default:
      return '';
  }
};

interface AdProps {
  leagueSlug?: string;
  adType?: string;
  section?: string;
  articleId?: number;
}

const trackImpression = () => logEvent('ad_impression');
const imageLoaded = () => window.dispatchEvent(new Event('image-loaded'));

// Set Title for unique title for accessibility
const setTitle = (event, adType) => {
  const slot = event.slot;
  const id = slot.getSlotElementId();
  const adElement = document.getElementById(id);

  if (!adElement) {
    return;
  }

  const iframeEl = adElement.querySelector('iframe');

  if (iframeEl) {
    const title = iframeEl.getAttribute('title');

    iframeEl.setAttribute('title', `${title} - ${adType}`);
  }
};

const simulateAdClick = (event, isAdLoaded) => {
  if (isAdLoaded && (event.key === 'Enter' || event.key === ' ')) {
    const iframeEl = event.target.getElementsByTagName('iframe')[0];

    if (iframeEl) {
      event.preventDefault();
      iframeEl.contentWindow.document.getElementsByTagName('a')[0].click();
    }
  }
};

// tslint:disable-next-line variable-name
export const Ad: React.SFC<AdProps> = props => {
  let isLoaded = false;
  const adUnitBase = DFP_AD_BASE;
  const { adType, leagueSlug, section, articleId } = props;
  const slotParams = getSlotParams(adType);
  const targetingParams = {
    section,
    league: leagueSlug,
    articleID: articleId,
  };

  const containerClass = `${getContainerClass(adType)} ${css.container}`;

  return (
    <div className={containerClass} onKeyPress={event => simulateAdClick(event, isLoaded)}>
      {adType === AdTypes.BIGBOX_DESKTOP && <div className={css.adText}>Advertisement</div>}
      {adUnitBase && (
        <GPT
          adUnitPath={`${adUnitBase}-${slotParams.adUnit}`}
          sizeMapping={slotParams.sizeMapping}
          outOfPage={slotParams.outOfPage}
          targeting={targetingParams}
          onSlotOnload={event => setTitle(event, adType)}
          onSlotRenderEnded={() => {
            isLoaded = true;
            imageLoaded();
          }}
          onImpressionViewable={trackImpression}
          collapseEmptyDiv
        />
      )}
    </div>
  );
};

Ad.displayName = 'Ad';

Ad.defaultProps = {
  leagueSlug: 'top_news',
  section: '',
  articleId: null,
  adType: AdTypes.BIGBOX_DESKTOP,
};

export default Styled(Ad, [css]);
